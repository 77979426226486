import { CompanyType } from '@monorepo/deprecated/interfaces'

export const initialState: CompanyType = {
  name: 'Территория MMC',
  address: {
    country: 'РФ',
    // region: '',
    city: 'г. Москва',
    // street: 'Алтуфьевское шоссе',
    // building: 'д. 27А стр. 2',
    // additional: 'База Управления Механизации № 37',
    street: '1-я улица Измайловского Зверинца',
    building: 'дом 8, подьезд 2, этаж 7',
  },
  phones: [
    {
      number: '+7 (495) 669-96-28',
      name: '',
      isMain: true,
    },
    {
      number: '+7 (906) 089-88-99',
      name: '',
      isMain: false,
    },
  ],
  socialNetworks: [
    // {
    //   name: 'facebook',
    //   url: '',
    // },
    {
      name: 'vk',
      url: 'https://vk.com/club71668987',
    },
    // {
    //   name: 'instagram',
    //   url: '',
    // },
    // {
    //   name: 'twitter',
    //   url: '',
    // },
  ],
  messagers: {
    // skype: '',
    // viber: '',
  },
  email: 'auto_stol@mail.ru',
  businessHours: [
    [8.5, 18],
    [8.5, 18],
    [8.5, 18],
    [8.5, 18],
    [8.5, 18],
    [8.5, 18],
    [8.5, 18],
  ],
  mapPlace: {
    // center: { lon: 55.857426, lat: 37.581102 },
    center: { lon: 55.774806, lat: 37.746196 },
    zoom: 17,
    // url: 'https://yandex.ru/maps/-/CSq5BBiE',
    url: 'https://yandex.ru/maps/-/CHqBVQ6s',
  },
  offices: [
    {
      id: 1,
      isSalesPoint: true,
      name: 'Территория MMC',
      address: {
        country: 'РФ',
        // region: '',
        city: 'г. Москва',
        // street: 'Алтуфьевское шоссе',
        // building: 'д. 27А стр. 2',
        // additional: 'База Управления Механизации № 37',
        street: '1-я улица Измайловского Зверинца',
        building: 'дом 8, подьезд 2, этаж 7',
      },
      phones: [
        {
          number: '+7 (495) 669-96-28',
          name: '',
          isMain: true,
        },
        {
          number: '+7 (906) 089-88-99',
          name: '',
          isMain: false,
        },
      ],
      socialNetworks: [
        // {
        //   name: 'facebook',
        //   url: '',
        // },
        {
          name: 'vk',
          url: 'https://vk.com/club71668987',
        },
        // {
        //   name: 'instagram',
        //   url: '',
        // },
        // {
        //   name: 'twitter',
        //   url: '',
        // },
      ],
      messagers: {
        // skype: '',
        // viber: '',
      },
      email: 'auto_stol@mail.ru',
      businessHours: [
        [8.5, 18],
        [8.5, 18],
        [8.5, 18],
        [8.5, 18],
        [8.5, 18],
        [8.5, 18],
        [8.5, 18],
      ],
      mapPlace: {
        // center: { lon: 55.857426, lat: 37.581102 },
        center: { lon: 55.774806, lat: 37.746196 },
        zoom: 17,
        // url: 'https://yandex.ru/maps/-/CSq5BBiE',
        url: 'https://yandex.ru/maps/-/CHqBVQ6s',
      },
    },
  ],
}

// export default initialState
